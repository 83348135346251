#organization-dashboard {
    // padding-top: 80px;
    // background-image: url("../../assets/agency-doodle-concept.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
    // box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);

    padding-top: 80px;
    background-image: url("https://images.unsplash.com/photo-1558036117-15d82a90b9b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
    // background-image: url("../../assets/agency-doodle-concept.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);
    min-height: 100vh;

    position: relative;

    .dashboard-container {
        margin: 0 auto;
        // width: 1060px;
        background: linear-gradient(120deg, hsla(0%, 0%, 0%, 0.3), hsla(0%, 0%, 0%, 0.5));

        @media screen and(min-width: 576px) {
            max-width: 560px;
        }

        @media screen and(min-width: 768px) {
            max-width: 690px;
        }

        @media screen and(min-width: 992px) {
            max-width: 850px;
        }

        @media screen and(min-width: 1200px) {
            max-width: 1060px;
        }
    }
}
