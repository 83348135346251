.App {
    text-align: center;
  }
  
  .App-logo {
    height: 16vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: My-logo-grow infinite 3s;
    }
  }
  
  .App-header {
    background-color: #fff;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes My-logo-grow {
    0% {
      transform: scale(1,1);
      opacity: 0.3;
  }
  45% {
      transform: scale(1.5,1.5);
      opacity: 1;
  }
  100% {
      transform: scale(1,1);
      opacity: 0.3;
  }
  }
  