.container {
    margin-left: auto;
    margin-right: auto;

    @media screen and(min-width: 576px) {
        max-width: 480px;
    }

    @media screen and(min-width: 768px) {
        max-width: 640px;
    }

    @media screen and(min-width: 992px) {
        max-width: 720px;
    }

    @media screen and(min-width: 1200px) {
        max-width: 1180px;
    }
}

.container-sm {
    margin-left: auto;
    margin-right: auto;

    @media screen and(min-width: 576px) {
        max-width: 480px;
    }

    @media screen and(min-width: 768px) {
        max-width: 640px;
    }

    @media screen and(min-width: 992px) {
        max-width: 690px;
    }

    @media screen and(min-width: 1200px) {
        max-width: 1080px;
    }
}

.container-xm {
    margin-left: auto;
    margin-right: auto;

    @media screen and(min-width: 576px) {
        max-width: 480px;
    }

    @media screen and(min-width: 768px) {
        max-width: 620px;
    }

    @media screen and(min-width: 992px) {
        max-width: 640px;
    }

    @media screen and(min-width: 1200px) {
        max-width: 990px;
    }
}

.container-xxm {
    margin-left: auto;
    margin-right: auto;

    @media screen and(min-width: 576px) {
        max-width: 560px;
    }

    @media screen and(min-width: 768px) {
        max-width: 750px;
    }

    @media screen and(min-width: 992px) {
        max-width: 740px;
    }

    @media screen and(min-width: 1200px) {
        max-width: 800px;
    }
}

.w-360 {
    max-width: 360px;
}

.w-480 {
    max-width: 480px;
}

.w-640 {
    max-width: 640px;
}

.w-720 {
    max-width: 720px;
}

.w-900 {
    max-width: 900px;
}

@-webkit-keyframes DelightfulBubbles__fadeIn {
    to {
        opacity: 1;
    }
}

@-webkit-keyframes DelightfulBubbles__growShrink {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@-webkit-keyframes DelightfulBubbles__swirl {
    0% {
        transform: rotate(0deg) translateX(-50%);
    }
    100% {
        transform: rotate(-360deg) translateX(-50%);
    }
}

@-webkit-keyframes DelightfulBubbles__colorChange_yellow {
    0% {
        background-color: #fab904;
    }
    50% {
        background-color: #ff6400;
    }
}

@-webkit-keyframes DelightfulBubbles__colorChange_orange {
    0% {
        background-color: #ff6400;
    }
    50% {
        background-color: #0fa573;
    }
}

@-webkit-keyframes DelightfulBubbles__colorChange_green {
    0% {
        background-color: #0fa573;
    }
    50% {
        background-color: #0074e8;
    }
}

@-webkit-keyframes DelightfulBubbles__colorChange_blue {
    0% {
        background-color: #0074e8;
    }
    50% {
        background-color: #b469eb;
    }
}

@-webkit-keyframes DelightfulBubbles__colorChange_purple {
    0% {
        background-color: #b469eb;
    }
    50% {
        background-color: #fab904;
    }
}

@keyframes DelightfulBubbles__fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes DelightfulBubbles__growShrink {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes DelightfulBubbles__swirl {
    0% {
        transform: rotate(0deg) translateX(-50%);
    }
    100% {
        transform: rotate(-360deg) translateX(-50%);
    }
}

@keyframes DelightfulBubbles__colorChange_yellow {
    0% {
        background-color: #fab904;
    }
    50% {
        background-color: #ff6400;
    }
}

@keyframes DelightfulBubbles__colorChange_orange {
    0% {
        background-color: #ff6400;
    }
    50% {
        background-color: #0fa573;
    }
}

@keyframes DelightfulBubbles__colorChange_green {
    0% {
        background-color: #0fa573;
    }
    50% {
        background-color: #0074e8;
    }
}

@keyframes DelightfulBubbles__colorChange_blue {
    0% {
        background-color: #0074e8;
    }
    50% {
        background-color: #b469eb;
    }
}

@keyframes DelightfulBubbles__colorChange_purple {
    0% {
        background-color: #b469eb;
    }
    50% {
        background-color: #fab904;
    }
}
