#profile-page {
    @media screen and (min-width: 800px) {
        .profile-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 799px) {
        .profile-header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }
}
