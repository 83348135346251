#register-page {
    background: linear-gradient(120deg, #eff1fa, #8d97b5);

    .register-card {
        background: linear-gradient(120deg, #fff, #a2adcc);
        border-radius: 10px;
        width: 750px;
        height: 500px;
        box-shadow: 2px 2px 10px #8d97b5;
        margin: auto auto;
        padding: 15px 40px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .h3-0 {
        margin: 0 auto;
    }

    .register-button {
        background-color: #ff1a4f;
        box-shadow: 2px 2px 10px #8d97b5;
        padding: 10px 15px;
        border-radius: 10px;
        transition: ease-in 0.2s;
    }

    .register-button:hover {
        background-color: #e60036;
    }
}

#owner-registration {
    min-height: 100vh;
    padding-bottom: 0;
    margin-bottom: 0;
    background: #fff;

    .form-card {
        box-shadow: 2px 2px 8px #cecece;
        padding: 20px 30px;
        border-radius: 7px;
    }

    .hero {
        background: #fff;
        padding: 80px 10px 50px;
        margin-top: 80px;
    }

    .image-background {
        background: #fff;
        padding: 0 10px 50px;
        background-image: url("../../assets/city-skyline-concept-illustration.jpg");
        background-size: contain;
        background-position: center bottom;
        background-repeat: repeat-x;
        height: 200px;
    }

    .registration-section {
        background: #f0f9ff;
        // background: rgb(220, 246, 255);
        padding: 70px 10px 70px;
    }
}

#organization-registration {
    min-height: 100vh;
    padding-bottom: 0;
    margin-bottom: 0;
    background: #fff;

    .form-card {
        box-shadow: 2px 2px 8px #cecece;
        padding: 20px 30px;
        border-radius: 7px;
    }

    .hero {
        background: #fff;
        padding: 80px 10px 50px;
        margin-top: 80px;
    }

    .image-background {
        background: #fff;
        padding: 0 10px 50px;
        background-image: url("../../assets/city-building-view-daytime.jpg");
        background-size: contain;
        background-position: center bottom;
        background-repeat: repeat-x;
        height: 200px;
    }

    .registration-section {
        background: #f0f9ff;
        padding: 70px 10px 70px;
    }
}
