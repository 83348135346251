/*
 * App layout
*/
.site-layout .site-layout-background {
	background: #fff;
}

.round-edge {
	border-radius: 10px;
}

.menu-item:hover {
	color: #4d33dc;
}
.menu-item {
	background-color: #4d33dc;
}
.logo {
	margin: 10px 20px;
	font-size: 20px;
}
.header-components {
	margin-right: 20px;
	float: right;
}

.header-icons {
	font-size: 24;
}

div.document-box {
	width: 250px;
	margin-bottom: 10px;
}

.component-card {
	margin: 10px;
}
.card-content {
	margin: 10px;
}
.recommendations-card {
	margin: 10px;
}
.preference-bar {
	margin-right: 20px;
	width: 100%;
}
.mobile-menu-item {
	visibility: hidden;
}

/*
 * Profile route
*/
#profile-background {
	background-image: url("https://wallpaperaccess.com/full/2331346.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 250px;
	max-height: 250px;
	height: 250px;
	padding-top: 164px;
}
#profile-block {
	margin: auto;
	width: 33.33%;
	background-color: transparent;
	border-radius: 5px;
}
#profile-picture {
	position: relative;
	margin: auto;
	min-width: 128px !important;
	width: 128px !important;
}
.profile-detail-block {
	margin: auto;
	width: 33.33%;
	text-align: left;
}
.change-profile-picture {
	color: #f0f2f5;
	position: absolute;
	right: 0;
	bottom: 15px;
	z-index: 1;
}
.change-profile-picture:hover {
	cursor: pointer;
}

/* Replace red with the desired color

.ant-slider-handle,
.ant-slider-handle-1,
.ant-slider-handle.ant-slider-handle-1,
.ant-slider-handle.ant-slider-handle-2,
.ant-slider-handle:hover,
.ant-slider-handle-1:hover,
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging,
.ant-slider-handle.ant-tooltip-open,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
	border-color: red;
}
.ant-slider-track,
.ant-slider-track-1 {
	border-color: red;
	background-color: red;
}
.ant-slider:hover .ant-slider-track {
	background-color: red;
} */

/* Mobile display*/
.profile-row {
	width: 100%;
	display: inline-flex;
}
#first-block,
#second-block {
	width: 50%;
}

/*
 * Floating action button
*/
.floating-action {
	position: fixed;
	right: 10%;
	z-index: 1000;
	bottom: 20px;
	display: inline;
}

/*
 * Support for various screen sizes
*/
@media screen and (max-width: 1440px) {
	.documents-array {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}
	.add-preference-button {
		margin-left: 95%;
	}

	div.document-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}
	.saved-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}
	.mobile-menu-item {
		visibility: hidden !important;
		display: none !important;
	}
	.floating-action {
		display: none;
	}
}

/* Small laptops*/
@media screen and (max-width: 1024px) {
	.documents-array {
		display: grid;
		grid-template-columns: repeat(3, 33.3333%);
	}

	.add-preference-button {
		margin-left: 92%;
	}

	div.document-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}

	.saved-container {
		display: grid;
		grid-template-columns: repeat(3, 33.3333%);
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: repeat(3, 33.3333%);
	}
	.mobile-menu-item {
		visibility: hidden !important;
		display: none !important;
	}
	.floating-action {
		display: none;
	}
	/* #profile-block {
		margin: auto !important;
		width: 50% !important;
	}

	#profile-picture {
		margin: auto !important;
		width: 50% !important;
	} */
}

/* Tablet*/
@media screen and (max-width: 768px) {
	.documents-array {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}
	div.document-box {
		width: 100%;
		margin-bottom: 20px;
	}
	.add-preference-button {
		margin-left: 85%;
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: 100%;
		margin: auto;
	}
	.saved-container {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}

	.mobile-menu-item {
		visibility: hidden !important;
		display: none !important;
	}
	.floating-action {
		display: none;
	}

	/* #profile-block {
		margin: auto !important;
		width: 50% !important;
	}

	#profile-picture {
		margin: auto !important;
		width: 50% !important;
	} */
}

/* Mobile phones*/
@media screen and (max-width: 425px) {
	.documents-array {
		display: grid;
		grid-template-columns: 100%;
	}
	div.document-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: 100%;
		margin: auto;
	}

	.saved-container {
		display: grid;
		grid-template-columns: 100%;
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: 100%;
	}
	.sidebar-menu {
		visibility: hidden !important;
		display: none !important;
	}
	.header-components {
		display: none !important;
	}
	.mobile-menu-item {
		visibility: visible !important;
		display: inline-block !important;
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	/* .top-header {
		position: fixed;
		z-index: 1;
		width: 100%;
	} */
	.main-content {
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 0 !important;
		width: 100%;
		position: relative;
	}
	.round-edge {
		border-radius: 0%;
	}
	.card-content {
		width: 100% !important;
		margin-right: 0% !important;
		margin-left: 0% !important;
	}
	.profile-row {
		display: block;
	}
	#first-block,
	#second-block {
		width: 100%;
	}
	.profile-detail-block {
		width: 100%;
	}
	.floating-action {
		display: inline;
	}
}

/* Mobile phones*/
@media screen and (max-width: 375px) {
	.documents-array {
		display: grid;
		grid-template-columns: 100%;
	}

	div.document-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: 100%;
	}

	.saved-container {
		display: grid;
		grid-template-columns: 100%;
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: 100%;
	}
	.sidebar-menu {
		visibility: hidden !important;
		display: none !important;
	}
	.mobile-menu-item {
		visibility: visible !important;
		display: inline-block !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.profile-row {
		display: block;
	}
	#first-block,
	#second-block {
		width: 100%;
	}
	.profile-detail-block {
		width: 100%;
	}
	.floating-action {
		display: inline;
	}
}

/* Mobile phones*/
@media screen and (max-width: 320px) {
	.mobile-menu-item {
		visibility: visible !important;
		display: inline-block !important;
		padding-left: 6px !important;
		padding-right: 6px !important;
	}
	.profile-row {
		display: block;
	}
	#first-block,
	#second-block {
		width: 100%;
	}
	.profile-detail-block {
		width: 100%;
	}
	.floating-action {
		display: inline;
	}
}

/* Extra large screens*/
@media screen and (min-width: 1450px) {
	.documents-array {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}

	.site-card-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 50%);
	}
	.saved-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}

	.recommendations-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
	}
	.add-preference-button {
		margin-left: 95%;
	}
	.floating-action {
		display: none;
	}
}
.preference-table {
	width: 100%;
}
