.inbox-banner-ev {
    max-height: 223px;
}
.inbox-ev {
    background: rgba(255, 255, 255, 0.7);
    display: table;
    border-radius: 5px;
    position: relative;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15) !important;
    width: 100%;
    margin: -24px;
}

.inbox-ev .messenger-list-ev {
    display: table-cell;
    width: 30%;
    border-right: 1px solid #f4f6fa;
    vertical-align: top;
}
.inbox-ev .messenger-list-ev .search-bar-ev {
    padding: 20px 30px 20px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox-ev .messenger-list-ev .search-bar-ev input {
    border: none;
    width: 100%;
    background: url("../assets/search-gray.svg") no-repeat left center;
    background-position-x: 15px;
    background-size: 15px 15px;
    padding-left: 38px;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
    border: 1px solid transparent;
}
.inbox-ev .messenger-list-ev .search-bar-ev input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #f1f1f1;
    border-radius: 30px;
}
.inbox-ev .messenger-list-ev button {
    border-radius: 20px;
    margin: 20px 25px;
    padding: 7px 20px;
    font-size: 13px;
    font-weight: normal;
}
.inbox-ev .messenger-list-ev ul.message-list-ev {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    float: left;
    height: 405px;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li {
    position: relative;
    width: 100%;
    background: transparent;
    padding: 20px 25px;
    transition: all 0.5s ease;
    border-bottom: 1px solid #f4f6fa;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li.active-ev {
    background: rgba(28, 133, 255, 0.05);
}
.inbox-ev .messenger-list-ev ul.message-list-ev li.active:before {
    content: "";
    border-left: 3px solid #1c85ff;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li.read-ev a div.messenger-message-ev div.message-summery-ev {
    font-weight: normal;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li.unread-ev {
    background: #fafafa;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li.unread-ev a div.messenger-message-ev div.message-summery-ev {
    font-weight: 500;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li:last-child {
    border-bottom: none;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li:first-child {
    border-top: 1px solid #f4f6fa;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li:hover {
    background: #f5f6fa;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a {
    display: block;
    color: #424457;
    text-decoration: none;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-icon-ev {
    display: inline-block;
    position: relative;
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
    vertical-align: top;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-icon-ev img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-icon-ev i {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-message-ev {
    display: inline-block;
    width: calc(60% - 55px);
    vertical-align: top;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-message-ev h5.message-title-ev {
    font-size: 12px;
    font-weight: bold;
    margin: 4px 0px 0px;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a div.messenger-message-ev div.message-summery-ev {
    font-size: 11px;
    width: 75%;
    line-height: 2em;
}
.inbox-ev .messenger-list-ev ul.message-list-ev li a span.date-time-ev {
    position: absolute;
    top: 25px;
    right: 15px;
    font-size: 11px;
}
.inbox-ev .chat-box-ev {
    display: table-cell;
    width: 70%;
    position: relative;
}
.inbox-ev .chat-box-ev .chat-header-ev {
    padding: 10px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox-ev .chat-box-ev .chat-header-ev div.header-icon-ev {
    max-height: 60px;
    max-width: 60px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.inbox-ev .chat-box-ev .chat-header-ev div.header-icon-ev i {
    position: absolute;
    bottom: 0;
    right: 3px;
}
.inbox-ev .chat-box-ev .chat-header-ev div.header-icon-ev img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.user-icon-ev {
    font-weight: 600;
    user-select: none;
    font-size: 23px;
}
.inbox-ev .chat-box-ev .chat-header-ev div.header-details-ev {
    width: calc(100% - 85px);
    display: inline-block;
    vertical-align: top;
}
.inbox-ev .chat-box-ev .chat-header-ev div.header-details-ev h5.user-icon-ev {
    margin-bottom: 5px;
}
.inbox-ev .chat-box-ev .chatter-ev {
    width: 100%;
    float: left;
    padding: 20px 0px;
    height: 300px;
    margin-bottom: 130px;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev {
    max-width: 75%;
    clear: both;
    padding-bottom: 0px;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev:last-child {
    padding-bottom: 0;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.left-ev {
    float: left;
    padding-left: 40px;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.left-ev div.chatter-title-ev,
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.left-ev div.time-plate-ev {
    text-align: left;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.left-ev div.chatter-message-ev {
    border-radius: 20px 20px 20px 0;
    background: #edf0f5;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.right-ev {
    float: right;
    padding-right: 40px;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.right-ev div.chatter-title-ev,
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.right-ev div.time-plate-ev {
    text-align: right;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev.right-ev div.chatter-message-ev {
    border-radius: 20px 20px 0px 20px;
    background: #1c85ff;
    color: #fff;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev div.chatter-title-ev {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev div.chatter-message-ev {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev div.chatter-message-ev p {
    margin-bottom: 0;
}
.inbox-ev .chat-box-ev .chatter-ev .chat-wrapper-ev div.time-plate-ev {
    font-size: 11px;
    color: #afaeba;
    width: 100%;
}
.inbox .chat-box .chatter .chat-wrapper div.error-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: red;
}
.inbox-ev .chat-box-ev .chat-footer-ev {
    padding: 25px;
    border-top: 1px solid #f4f6fa;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 0 0 5px 0;
    width: 100%;
    background: #fff;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev {
    width: 100%;
    float: left;
    background: #edf2f6;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev textarea {
    border: none;
    font-size: 12px;
    width: calc(100% - 100px);
    float: left;
    background: transparent;
    margin-bottom: 10px;
    resize: none;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev textarea:focus {
    outline: none;
}
.chat-btn-ev {
    background: #3f51b5;
}

.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev textarea:focus ~ button.chat-btn-ev {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev {
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(100% - 100px);
    float: left;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li {
    display: inline-block;
    margin-right: 10px;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li a {
    display: block;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li a img {
    width: 15px;
    height: 15px;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev button.chat-btn-ev {
    width: 80px;
    position: absolute;
    top: 26px;
    right: 25px;
    border-radius: 25px;
    padding: 6px 15px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
}
.inbox-ev .chat-box-ev .chat-footer-ev .chat-input-ev button.chat-btn-ev img {
    width: 25px;
    height: 25px;
}

/*
 * Support for various screen sizes
*/
@media screen and (max-width: 1440px) {
}

/* Small laptops*/
@media screen and (max-width: 1024px) {
}

/* Tablet*/
@media screen and (max-width: 768px) {
    .inbox-ev .chat-box-ev {
        display: table-cell !important;
    }
    .inbox-ev .messenger-list-ev {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Mobile phones*/
@media screen and (max-width: 425px) {
    .inbox .chat-box-ev {
        display: table-cell !important;
    }
    .inbox-ev .messenger-list-ev {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Mobile phones*/
@media screen and (max-width: 375px) {
    .inbox-ev .chat-box-ev {
        display: table-cell !important;
    }
    .inbox-ev .messenger-list-ev {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Mobile phones*/
@media screen and (max-width: 320px) {
    .inbox-ev .chat-box-ev {
        display: table-cell !important;
    }
    .inbox-ev .messenger-list-ev {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Extra large screens*/
@media screen and (min-width: 1450px) {
}

.inbox-ev .chat-box1-ev {
    display: table-cell;
    width: 70%;
    position: relative;
}
.inbox-ev .chat-box1-ev .chat-header-ev {
    padding: 10px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox-ev .chat-box1-ev .chat-header-ev div.header-icon-ev {
    height: 60px;
    width: 60px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.inbox-ev .chat-box1-ev .chat-header-ev div.header-icon-ev i {
    position: absolute;
    bottom: 0;
    right: 3px;
}
.inbox-ev .chat-box1-ev .chat-header-ev div.header-icon-ev img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.inbox-ev .chat-box1-ev .chat-header-ev div.header-details-ev {
    width: calc(100% - 85px);
    display: inline-block;
    vertical-align: top;
}
.inbox-ev .chat-box1-ev .chat-header-ev div.header-details-ev h5.user-icon-ev {
    margin-bottom: 5px;
}
.inbox-ev .chat-box1-ev .chatter-ev {
    width: 100%;
    float: left;
    padding: 20px 0px;
    height: 300px;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev {
    max-width: 75%;
    clear: both;
    padding-bottom: 0px;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev:last-child {
    padding-bottom: 0;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.left-ev {
    float: left;
    padding-left: 40px;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.left-ev div.chatter-title-ev,
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.left-ev div.time-plate-ev {
    text-align: left;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.left-ev div.chatter-message-ev {
    border-radius: 20px 20px 20px 0;
    background: #edf0f5;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.right-ev {
    float: right;
    padding-right: 40px;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.right-ev div.chatter-title-ev,
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.right-ev div.time-plate-ev {
    text-align: right;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev.right-ev div.chatter-message-ev {
    border-radius: 20px 20px 0px 20px;
    background: #1c85ff;
    color: #fff;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev div.chatter-title-ev {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev div.chatter-message-ev {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev div.chatter-message-ev p {
    margin-bottom: 0;
}
.inbox-ev .chat-box1-ev .chatter-ev .chat-wrapper-ev div.time-plate-ev {
    font-size: 11px;
    color: #afaeba;
    width: 100%;
}
.inbox-ev .chat-box1-ev .chat-footer-ev {
    padding: 25px;
    border-top: 1px solid #f4f6fa;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 0 0 5px 0;
    width: 100%;
    background: #fff;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev {
    width: 100%;
    float: left;
    background: #edf2f6;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev textarea {
    border: none;
    font-size: 12px;
    width: calc(100% - 100px);
    float: left;
    background: transparent;
    margin-bottom: 10px;
    resize: none;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev textarea:focus {
    outline: none;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev textarea:focus ~ button.chat-btn-ev {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev {
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(100% - 100px);
    float: left;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li {
    display: inline-block;
    margin-right: 10px;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li a {
    display: block;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev ul.chat-action-ev li a img {
    width: 15px;
    height: 15px;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev button.chat-btn-ev {
    width: 80px;
    position: absolute;
    top: 26px;
    right: 25px;
    border-radius: 25px;
    padding: 6px 15px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
}
.inbox-ev .chat-box1-ev .chat-footer-ev .chat-input-ev button.chat-btn-ev img {
    width: 25px;
    height: 25px;
}
