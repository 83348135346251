.drawerTop {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 18vh;
  border-bottom: 1px solid grey;
}

.profileName {
  font-size: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 5px;
  font-weight: 700;
}

.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  color: grey;
}