#alert {
    .alert-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    img {
        width: auto;
        height: 100%;
    }

    .alert-image {
        margin: 0 auto 0;
        width: 70%;
        height: auto;
    }

    .alert-subject {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }

    .alert-subject {
        font-size: 12px;
        font-weight: lighter;
        text-align: center;
    }
}
