#property-search-page {
    @media screen and (min-width: 900px) {
        .sp-search {
            position: sticky;
            top: 60px;
            left: 0px;
            height: auto;
            padding: 15px 0;
            width: 100%;
            border-bottom: 1px solid #cecece;
            z-index: 4;
            background-color: #fff;
        }
    }
    @media screen and (max-width: 899px) {
        .sp-search {
            position: sticky;
            top: 48px;
            left: 0px;
            height: auto;
            padding: 15px 0;
            width: 100%;
            border-bottom: 1px solid #cecece;
            z-index: 4;
            background-color: #fff;
        }
    }
    // .sp-search {
    //     position: sticky;
    //     top: 60px;
    //     left: 0px;
    //     height: auto;
    //     padding: 15px 0;
    //     width: 100%;
    //     border-bottom: 1px solid #cecece;
    //     z-index: 4;
    //     background-color: #fff;
    // }

    .sc-filter {
        border: 1px solid #8c8c8c;
        height: 45px;
        color: #3089e8;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding: 5px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: ease-in 0.2s;
    }

    .sc-filter:hover {
        border: 1px solid #cecece;
    }
}

.sp-location-input {
    height: 45px;
    border: 1px solid #8c8c8c;
    padding: 7px 7px;
    width: 100%;
}

.filter-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #737373;
}

#property-card-m0 {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #cecece;
    margin: 0 auto;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 36px 28px -7px, rgb(0 0 0 / 4%) 0px 17px 17px -7px;

    // .property-image {
    //     width: 50%;
    //     height: 240px;
    //     background-size: cover;
    //     background-position: center;
    // }

    .location-text {
        color: #3089e8;
    }

    .acquisition-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        width: auto;
        background-color: yellow;
        color: black;
        font-weight: bold;
        padding: 1px 8px;
        border-radius: 2px;
        font-size: 10px;
        text-transform: uppercase;
    }

    .tag-text {
        text-transform: uppercase;
        font-size: 12px;
        color: grey;
    }

    .listing-line {
        font-size: 9px;
        color: grey;
    }
}

#property-card-m0-mobile {
    position: relative;
    border: 1px solid #cecece;
    margin: 0 auto;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 36px 28px -7px, rgb(0 0 0 / 4%) 0px 17px 17px -7px;

    // .property-image {
    //     width: 50%;
    //     height: 240px;
    //     background-size: cover;
    //     background-position: center;
    // }

    .location-text {
        color: #3089e8;
    }

    .acquisition-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        width: auto;
        background-color: yellow;
        color: black;
        font-weight: bold;
        padding: 1px 8px;
        border-radius: 2px;
        font-size: 10px;
        text-transform: uppercase;
    }

    .tag-text {
        text-transform: uppercase;
        font-size: 12px;
        color: grey;
    }

    .listing-line {
        font-size: 9px;
        color: grey;
    }
}
