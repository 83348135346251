#login {
    .user-type-select {
        border: 1px solid #000;
        border-radius: 5px;
        padding: 10px 10px;
        cursor: pointer;
        background-color: #fff;
    }

    .user-type-select:hover {
        border: 1px solid #2065d1;
    }

    .selected {
        background-color: #f0f9ff;
        border: 1px solid #2065d1;
    }

    .p-description {
        font-size: 12px;
    }

    .subject {
        font-size: 15px;
    }
}
