#update-property-page {
    // background-color: #fff;
    // min-height: 100vh;
    // background-image: url("https://images.unsplash.com/photo-1626178793926-22b28830aa30?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.4);

    // .list-property-card {
    //     box-shadow: 2px 2px 10px #cecece;
    //     margin-top: 90px;
    //     padding: 40px 80px 40px;
    //     background-color: #fff;
    //     border-radius: 15px;
    // }

    @media screen and (min-width: 750px) {
        .list-property-card {
            box-shadow: 2px 2px 10px #cecece;
            margin-top: 0px;
            padding: 40px 80px 40px;
            background-color: #fff;
            border-radius: 15px;
        }
    }
    @media screen and (max-width: 750px) {
        .list-property-card {
            box-shadow: 2px 2px 10px #cecece;
            margin-top: 0px;
            margin-right: 10px;
            margin-left: 10px;
            padding: 20px 20px 20px;
            background-color: #fff;
            border-radius: 15px;
        }
    }

    .counter-button-l {
        width: 40px;
        height: 35px;
        background-color: #000;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .counter-button-l:hover {
        box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.4);
    }
}
