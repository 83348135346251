#organization-dashboard {
    padding-top: 80px;
    // background-image: url("../../assets/agency-doodle-concept.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
    // box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);
    background: linear-gradient(180deg, hsla(0%, 0%, 0%, 1), hsla(0%, 0%, 0%, 0.1)), url("../../assets/agency-doodle-concept.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 100vh;

    .dashboard-container {
        margin: 0 auto;
        // width: 1060px;
        background: linear-gradient(120deg, hsla(0%, 0%, 0%, 0.3), hsla(0%, 0%, 0%, 0.5));

        @media screen and(min-width: 576px) {
            max-width: 560px;
        }

        @media screen and(min-width: 768px) {
            max-width: 690px;
        }

        @media screen and(min-width: 992px) {
            max-width: 850px;
        }

        @media screen and(min-width: 1200px) {
            max-width: 1060px;
        }
    }
}
