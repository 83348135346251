#organization-id {
    position: sticky;
    top: 10px;
    left: 0;
    z-index: 4;
    background: #fff;
    box-shadow: 2px 2px 8px #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 12px;
    border-radius: 7px;
}
