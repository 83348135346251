:root {
    --score-line-color: #ffffff10;
    --text-color: #aaa;
    --text-active-color: #ff8a00;
}
  
.notifications{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: hsl(0,0%, 0%, .7);
    backdrop-filter: blur(2px);
    color: white;
    padding: 0 10px;
    max-width: 30vw;
}

.notifications-title{
    margin: 10px 0;
    font-weight: 700;
    font-size: 18px;
}

.notifications-body p{
    cursor: pointer;
    padding: 7px 0;
    border-bottom: 1px solid grey;
}

.notifications-body p:hover{
    background-color: grey;
    backdrop-filter: blur(12px);
}

.other-notifications{
    border-top: 1px solid white;
    width: 100%;
    margin-top: 10px;
    padding: 10px 0;
}

.other-notifications p{
    cursor: pointer;
    padding: 7px 0;
}

.other-notifications p:hover{
    background-color: grey;
    backdrop-filter: blur(12px);
}