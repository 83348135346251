#properties-db {
    width: 100%;
    min-height: 100vh;

    // .property-card {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: start;
    //     align-items: center;
    //     box-shadow: 2px 2px 20px #d3d3d3;
    //     padding: 25px 10px;
    //     border-radius: 9px;
    //     background-color: #fff;
    // }

    @media screen and (min-width: 800px) {
        .properties-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    @media screen and (max-width: 799px) {
        .properties-header {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
}

#property-card-m0 {
    position: relative;
    border: 1px solid #cecece;
    margin: 0 auto;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 36px 28px -7px, rgb(0 0 0 / 4%) 0px 17px 17px -7px;

    .location-text {
        color: #3089e8;
    }

    .acquisition-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        width: auto;
        background-color: yellow;
        color: black;
        font-weight: bold;
        padding: 1px 8px;
        border-radius: 2px;
        font-size: 10px;
        text-transform: uppercase;
    }

    .tag-text {
        text-transform: uppercase;
        font-size: 12px;
        color: grey;
    }

    .listing-line {
        font-size: 9px;
        color: grey;
    }
}
