#property-page {
    height: 100%;
    .residential-info {
        border-top: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        padding: 5px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .p-regular {
        font-size: 14px;
        line-height: 22px;
    }

    .p-description {
        font-size: 12px;
        line-height: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .action-button {
        width: 100%;
        height: 44px;
        padding: 5px 10px;
        margin: 10px 0;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        border: 1px solid grey;
        cursor: pointer;
    }

    .primary {
        background-color: #176fcf;
        color: white;
        transition: ease-in 0.2s;
    }
    .primary:hover {
        background-color: #3089e8;
    }

    .boxes {
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-right: 1px solid #cecece;
        width: 33%;
        height: 90px;
        padding: 5px 0;
    }
    .boxes:nth-last-child(1) {
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-right: 0px solid #cecece;
        width: 33%;
        height: 90px;
        padding: 5px 0;
    }

    .image-buttons {
        position: absolute;
        top: 200px;
        z-index: 2;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .next {
        right: 30px;
    }
    .prev {
        left: 20px;
    }

    @media screen and (max-width: 555px) {
        .view-mobile {
            display: block;
        }
        .breadcrumb-sp {
            font-size: 10px;
        }
    }
    @media screen and (min-width: 556px) {
        .view-mobile {
            display: none;
        }
        .breadcrumb-sp {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 555px) {
        .view-web {
            display: none;
        }
    }
    @media screen and (min-width: 556px) {
        .view-web {
            display: flex;
        }
    }
}
