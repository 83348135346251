.owner-action-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px;
    margin-right: 15px;
    background-image: linear-gradient(120deg, #ff1a4f, #ff809d);
    text-decoration: none;
}
