body {
    margin: 0;
    padding: 0;
  /*  display: flex; 
    flex-direction: column; 
    justify-content: center; */
    align-items: center;
    overflow-x: hidden;
    min-width: 100vw;
    min-height: 100vh;
    font-family: sans-serif;
    color: black;
}

.header-section {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    color: #ab32e5;
    background: white;
    border: 1px solid rgba(0,0,0,.1 );
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.Logo {
    width: 10vw;
    height: auto;
}

.box-reset-password {
    position: relative; 
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    margin-top: 10px;
    width: 99vw;
    background: white;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,.1 );
    border-radius: 9px 9px 9px 9px;
    box-shadow: 0 3px 7px rgba(0,0,0,.2);
}

.auth-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    margin-bottom: 10px;
    border-bottom: .5px solid grey;
}

.box-reset-password .auth_header_text {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
    width: 99vw;
    padding: 0;
    color: #333 !important;
    /* font-family: "Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
    font-family: "Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif";   
    /* font-size: 1.94rem; */
    font-size: 1.5rem;
    text-transform: capitalize;
}

.input-holder {
  display: flex;
  width: 100%;
  justify-content: center;
  color: black;
}

/*change */
.box-reset-password input {
    padding: 10px 0;
    margin-bottom: 30px;
    width: 90%;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: none;
    background-color: hsl(0,0%,100%,0);;
    outline: none;
    border: none;
    border: 2px solid black/*#999*/;
    border-radius: 10px 10px 10px 10px;
    padding-left: 5%;
    color: black;
}

.box-reset-password button[type="submit"] {
    width: 90%;
    border-bottom: none;
    cursor: pointer;
    background: #ab32e5;
    color: #fff;
    margin-bottom: 0;
    text-transform: uppercase;
    border-radius: 5px;
}

.box-reset-password form div {
    position: relative;
}

.box-reset-password form div label {
    position: absolute;
    top: 10px;   /*changes*/
    left: 8%;
    pointer-events: none;
    color: #999;
    transition: .5s;
    background-color: white; /*change */
}

.box-reset-password form div .show_password {
    position: absolute;
    top: 13px;   /*changes*/
    right: 8%;
    font-size: 18px;
    cursor: pointer;
    color: black;
    user-select: none;
}

.show_password2{
    position: absolute;
    top: 13px;   /*changes*/
    right: 8%;
    font-size: 18px;
    cursor: pointer;
    color: black;
    user-select: none;
}

.box-reset-password input:focus ~label,
.box-reset-password input:valid ~label
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    padding-right: 1%;
    font-size: 11px;
    font-weight: bold;
}

/* internal input */
.input:focus ~ .label,
.input:valid ~ .label
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    background-color: white;
    padding-right: 1%;
    font-size: 11px;
    font-weight: bold;
}

/*first step on narrow */
.input:focus ~ .label-last,
.input:valid ~ .label-last
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    font-size: 11px;
    font-weight: bold;
    background-color: white;
    padding-right: 1%;
}

.input-password {
    color: black;
    font-size: 14px;
    font-weight: 700;
}

.input-password:focus ~ .label-password,
.input-password:valid ~ .label-password
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    background-color: white;
    padding-right: 1%;
    font-size: 11px;
    font-weight: bold;
}



.label {
    position: absolute;
    top: 10px;
    left: 8%;
    font-size: 12px;
    pointer-events: none;
    font-weight: bolder;
    color: #999/*#999*/;
    transition: .5s;
}

.label-last {
    position: absolute;
    top: 10px;
    left: 8%;
    font-size: 12px;
    pointer-events: none;
    font-weight: bolder;
    color: #999/*#999*/;
    transition: .5s;
}

.label-password {
    position: absolute;
    top: 10px;
    left: 8%;
    font-size: 12px;
    pointer-events: none;
    font-weight: bolder;
    color: #999/*#999*/;
    transition: .5s;
}

.h-01 {
   width: 60vw;
   display: flex;
   justify-content: center;
}

.input {
    padding: 10px 0;
    margin-bottom: 30px;
    width: 90%;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: none;
    background-color: hsl(0,0%,100%,0);
    outline: none;
    border: none;
    border-radius: 10px 10px 10px 10px;
    padding-left: 5%;
    border: 2px solid black/*#999*/;
    color: black;
}

.input-password {
    padding: 10px 0;
    margin-bottom: 30px;
    width: 90%;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
    background-color: hsl(0,0%,100%,0);
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 10px 10px 10px 10px;
    padding-left: 3%;
    border: 2px solid black/*#999*/;
}


.input:focus ,
.input:valid,
.input-password:focus,
.input-password:valid
{
    border: 2px solid #ab32e5; 
}

/*end of internal input */ 


.box-reset-password input:focus ,
.box-reset-password input:valid,
.input-password:focus,
.input-password:valid
{
    border: 2px solid #ab32e5; /*change*/
}

@media (max-width: 600px) {
    .label-last {
        position: absolute;
        top: 10px;
        left: 8%;
        font-size: 12px;
        pointer-events: none;
        font-weight: bolder;
        color: #999/*#999*/;
        transition: .5s;
    }    

    .input {
        padding: 10px 0;
        margin-bottom: 30px;
        width: 90%;
        font-size: 14px;
        font-weight: 700;
        box-sizing: border-box;
        box-shadow: none;
        outline: none;
        border: none;
        background-color: hsl(0,0%,100%,0);;
        border: 2px solid black/*#999*/;
        padding-left: 5%;
        color: black;
    }

    .label {
    position: absolute;
    top: 10px;
    left: 8%;
    font-size: 12px;
    pointer-events: none;
    font-weight: bolder;
    color: #999/*#999*/;
    transition: .5s;
  }

  /*problem area  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
  .input:focus ~ .label,
.input:valid ~ .label
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    font-size: 11px;
    font-weight: bold;
    background-color: white;
    padding-right: 1%;
}

.input:focus ~ .label-last,
.input:valid ~ .label-last
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    font-size: 11px;
    padding-right: 1%;
    font-weight: bold;
    background-color: white;
}

    
}


.forgot-password_signup {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    color: #ab32e5;
    font-size: 10px;
}

.forgot-password_signup p:hover {
text-decoration: underline;
}

.forgot-password {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #ab32e5;
    font-size: 10px;
}

.forgot-password p:hover {
text-decoration: underline;
}


/* Dropdown Button */
.dropbtn {
    background-color: #ab32e5;
    color: white;
    padding: 16px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  /* Dropdown button on hover & focus */
  .dropbtn:hover, .dropbtn:focus {
    background-color: purple ;
  }
  
  /* The search field */
  #myInput {
    box-sizing: border-box;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 14px;
    padding: 14px 20px 12px 45px;
    border: none;
    border-bottom: 1px solid #ddd;
  }
  
  /* The search field when it gets focus/clicked on */
  #myInput:focus {outline: 3px solid #ddd;}
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    margin-bottom: 15px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
      background-color: #f1f1f1;
      color: #ab32e5;
      }

  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
  .show {display:block;}
  

  @media(min-width: 700px){
    .box-reset-password {
        position: relative; 
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%); 
        width: 60vw;
        background: white;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid rgba(0,0,0,.1 );
        box-shadow: 0 3px 7px rgba(0,0,0,.2);
        border-radius: 9px 9px 9px 9px;
    }    

    .forgot-password_signup { 
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    color: #ab32e5;
    font-size: 12px;
   }

    .forgot-password_signup p:hover {
    text-decoration: underline;
   }

   .forgot-password { 
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #ab32e5;
    font-size: 12px;
   }

    .forgot-password p:hover {
    text-decoration: underline;
   }


    .box-reset-password .auth_header_text {
     display: flex;
     justify-content: center;
     margin: 0 0 40px;
     width: 60vw;
     padding: 0;
     color: #333;
     /* font-family: "Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
     font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;   
     font-weight: bolder;
     text-transform: capitalize;
     font-size: 23px;
   }

   .label-last {
    position: absolute;
    top: 10px;
    left: 15%;
    font-size: 12px;
    pointer-events: none;
    font-weight: bolder;
    color: #999/*#999*/;
    transition: .5s;
    } 
    
    .input:focus ~ .label-last,
    .input:valid ~ .label-last
   {
    top: -12px;
    left: 15%;
    color: #ab32e5;
    padding-right: 1%;
    font-size: 11px;
    font-weight: bold;
    background-color: white;
   }

   .box-reset-password input:focus ~label,
   .box-reset-password input:valid ~label
{
    top: -12px;
    left: 8%;
    color: #ab32e5;
    font-size: 11px;
    padding-right: 1%;
    font-weight: bold;
    background-color: white;
}


  }

  /* Hide Arrows in number fields */

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-signup-holder {
    position: absolute;
    visibility: hidden;
    top: 0;
    display: flex;
    overflow-y: hidden;
    z-index: -10;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background: hsl(0,0%,0%,0.6);
}

.modal-signup {
    overflow-y: scroll;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    position: relative;
    opacity: 1;
    z-index: 5;
    transition: 1.5s; 
    transform: translateY(-8vh);
}

.modal-login-holder {
    position: fixed;
    visibility: hidden;
    top: 0;
    display: flex;
    overflow-y: hidden;
    z-index: -10;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background: hsl(0,0%,0%,0.6);
}

.modal-login {
    overflow-y: scroll;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: -80px;
    /* top: -40px */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative;
    opacity: 1;
    z-index: 5;
    transition: 1.5s; 
    transform: translateY(-8vh);
}

.modal-forgot_password-holder {
    position: fixed;
    visibility: hidden;
    top: 0;
    display: flex;
    overflow-y: hidden;
    z-index: -10;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background: hsl(0,0%,0%,0.6);
}

.modal-forgot_password {
    overflow-y: scroll;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    position: relative;
    opacity: 1;
    z-index: 5;
    transition: 1.5s; 
    transform: translateY(-8vh);
}

.login-text {
    position: relative;
    width: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.text-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 22px;
    text-transform: capitalize;
    color: black;
}

.close-section{
    width: 30px;
    top: 13px;
    right: 5%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: -10px;
    height: 30px;
    color: black !important;
    font-size: 22px !important;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: .5s;
}
.close-section:hover {
   border: 1px solid grey;
   border-radius: 50%;
}

/* .auth-modal-close {
    color: black !important;
} */

.auth-spinner {
    position: relative;
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: orangered;
    margin-bottom: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}


/* New form */
