.app-body {
  width: 100%;
  height: 100%; /* recently added */
}

body {
  padding: 0 !important;
}

.LandingBody {
  /* font-family: 'Courier New' ; */
  /* color: #ab32e5; */
  margin: 0;
  background: #03183f; /*#1b294a;**#001133;*/
  overflow-x: hidden;
  overflow-y: scroll;
  user-select: none;
}

/* .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
    z-index: 2;
} */

@media screen and (max-width: 845px) {
  .search-container {
    position: absolute;
    top: 180px;
    left: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* margin: 0 20px 0 20px; */
    z-index: 5;
  }
}
#bg-img-residential {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 350px;
  background: url("../assets/images/town_resized4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0.8;
  /* filter: blur(5px); */
}

#bg-img-commercial {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 350px;
  background: url("../assets/images/rsz_high_rises.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0.8;
}

#bg-img-agricultural {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 350px;
  background: url("../assets/images/rsz_farm1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0.8;
}
#bg-img-event {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 350px;
  background: url("../assets/images/rsz_event1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0.8;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #ab32e5;
  font-size: 40px;
  margin: 0;
}

.header-item-menu {
  z-index: 3;
  left: 5px;
  color: black;
}

.header-item-add {
  position: fixed;
  z-index: 3;
  right: 27px;
  top: 13vh;
  cursor: pointer;
}

.search-board {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
  overflow-y: hidden;
}

/* Search Container */

#search-toggle-property_type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  /* background: white; */
  border-radius: 5px 5px 0px 0px;
  width: 80vw;
  /* padding: 4px 12px; */
  height: 40px;
  color: #03183f; /*#ab32e5;*/
  font-size: 15px;
  /* margin-bottom: 1em; */
  z-index: 3;
  /* opacity: 0.7; */
}

#search-toggle-property_type .category-toggle {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-start;
  align-items: center;
  padding-right: 1vw;
  font-size: 30px;
  width: 20vw;
  height: 100%;
}

#search-toggle-property_type .category-text {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2vw;
  height: 100%;
  align-self: center;
  font-size: 13px;
  font-weight: bold;
}

.toggle-button {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* Section A */

.first-tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
}

#section-a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 0;
  z-index: 3;
}

.location-input {
  display: inline-block;
  padding: 0; /*4px 3px;*/
  font-size: 12px !important;
  width: 60vw !important;
  height: 40px;
  max-height: 40px;
  /*padding-left: 1.5vw;*/
  border-left: 1px solid grey;
  color: #03183f !important;
  background-color: rgba(255, 255, 255, 0.7);
  /* background-color: white; */
  -webkit-appearance: none;
  outline: none;
  /* opacity: 0.7; */
  /*-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  /*-moz-box-sizing: border-box;    /* Firefox, other Gecko */
  /*box-sizing: border-box;         /* Opera/IE 8+ */
}

input,
select,
textarea,
button {
  outline: none;
  border: hidden;
}

.in-more-holder {
  top: 250px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  margin-left: 2px;
  left: 10vw;
  padding-left: 4px;
  background: white;
}

.in-more-holder-mobile {
  top: 250px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  max-width: 80vw;
  height: 40px;
  margin-left: 2px;
  left: 10vw;
  padding-left: 4px;
  background: white;
  overflow-y: scroll;
}

.input-location-holder {
  top: 215px;
  position: absolute;
  margin-left: 2px;
}

.input-location-holder-more {
  top: 10px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.in-loc {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 7vw;
  font-size: 13px;
  background: yellow;
  margin-right: 3px;
  margin-left: 1px;
}

.in-loc-more {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 25vw;
  font-size: 13px;
  background: yellow;
  margin-right: 3px;
  margin-left: 1px;
}

.in-close {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 5vw;
  min-width: 5vw;
  border-radius: 0 4px 4px 0;
  background-color: goldenrod;
  color: black;
}

.loc-view-more {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 25px;
  width: 25vw;
  top: 40%;
  border-radius: 4px 4px 4px 4px;
  position: relative;
  color: #787878;
  background-color: #f0f0f0;
}

.more-locations {
  visibility: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  top: 30px;
  left: -0.4vw;
  height: 0px;
  max-height: 10vh;
  width: 80vw;
  background-color: white;
  z-index: 3;
  padding: 4px;
  transition: 0.6s;
  overflow-y: scroll;
}

.s1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.submit-button {
  display: inline-block; /*needed to align the toggle and input for search*/
  padding: 0.1px 0.1px; /*needed to align the toggle and input for search*/
  font-size: 20px;
  width: 15vw;
  border-radius: 0;
  -webkit-appearance: none;
}

.submit-button-2 {
  display: none;
  cursor: pointer;
  padding: 0.1px 0.1px;
  align-items: center;
  justify-content: center;
  width: 10vw;
  height: 40px;
  background: transparent;
  transition: 0.5s;
  z-index: 2;
}

.search-button {
  display: none;
}

.search-button-span {
  display: none;
  cursor: pointer;
  padding: 1.7px 3px;
  position: absolute;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 10vw;
  height: 40px;
  background: yellow;
  transition: 0.5s;
  z-index: 1;
}

.search-button-mobile {
  display: flex;
  margin-top: 0;
  justify-content: flex-end;
  align-items: center;
  width: 80vw;
  background-color: rgba(255, 255, 255, 0.7);
  /* background-color: white; */
  /* opacity: 0.7; */
}

.location-input:focus ~ .location-list {
  position: absolute;
  display: flex;
  top: 49px;
  width: 62vw;
  height: 30vh;
  background: white;
}

.submit-button-2-mobile {
  cursor: pointer;
  padding: 0.1px 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 40px;
  /* border-radius: 10px; */
  background: transparent;
  transition: 0.5s;
  z-index: 2;
}

.search-button-span-mobile {
  cursor: pointer;
  padding: 0.1px 0.1px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 80vw;
  height: 40px;
  /* border-radius: 10px; */
  background: #ffdf00;
  color: #03183f;
  font-weight: bold;
  transition: 0.5s;
  z-index: 1;
}

.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 40px;
  border-radius: 0 0 5px 5px;
  color: white;
  border: 0.5px solid #ffdf00;
  background-color: rgba(3, 24, 63, 0.7); /*#03183f;*/
  z-index: 2;
  /* opacity: 0.7; */
}

.toggle-buy {
  display: flex;
  padding: 0; /*3.8px 3px;*/
  align-items: center;
  justify-content: space-between;
  color: #03183f; /*#ab32e5;*/
  background-color: rgba(255, 255, 255, 0.7);
  /* background-color: white; */
  /* border-radius: 9px 0 0 9px ; */
  border-left: 2px black;
  border-bottom: 0px black;
  border-top: 0px black;
  font-size: 30px;
  width: 20vw;
  height: 40px;
  /*padding-left: 2vw;*/
  /* opacity: 0.7; */
  -webkit-appearance: none;
  text-transform: capitalize;
}

.toggle-buy span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bolder;
}

.type-field {
  display: none !important;
}

/* .toggle-buy p {
    font-size: 17px;
    font-weight: bolder;
} */

.purchase-status {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: bolder;
  margin: 0 !important;
  padding: 0 !important;
}

/* .toggle-buy span {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-right: 3px;
} */

.filter-toggle {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 18px;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.radio {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  visibility: visible;
  border: 2px solid white;
}

.search-text {
  font-size: 15px;
  margin-right: 4px;
  margin-left: 4px;
  color: grey;
}

@media (prefers-reduced-motion: no-preference) {
  .toggle-buys {
    animation: flip-text initial 1s;
  }
}

@keyframes flip-text {
  0% {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  100% {
  }
}

/* Section C */

#section-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.full-img {
  display: block;
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  border: solid 1px white;
}

.half-img {
  display: block;
  width: 50%;
  height: 40vh;
  object-fit: cover;
  border: solid 1px white;
}

.halfs {
  display: flex;
}

/* Section D */

#section-d {
  display: flex;
  max-height: 60px;
  background-color: #ab32e5;
  position: relative;
}

.info-section {
  display: flex;
  justify-content: space-between;
  padding: 0 2em 2em;
  border-top: solid 5px #ab32e5;
  height: 14px;
  background: white;
}

.info-section p {
  cursor: pointer;
}

.hide {
  color: white;
  font-size: 12px;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  border-top: solid 5px #ab32e5;
  height: 80px;
}

.logo-section p {
  font-size: 20px;
}

.aqivah-logo {
  padding-top: 10px;
  width: 60px;
  height: auto;
  margin: 0;
}

.logo-info {
  display: flex;
  flex-direction: column;
}

.twitter {
  color: royalblue;
  font-size: 20px;
}
.instagram {
  color: purple;
  font-size: 20px;
}
.facebook {
  color: blue;
  font-size: 20px;
}

.copywrite-section {
  display: flex;
  padding: 0 2em 2em;
  border-top: solid 5px #ab32e5;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.copywrite-section p {
  display: flex;
  justify-content: center;
  font-size: 11px;
  margin-right: 20px;
}

.copywrite-section div {
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

.footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
  color: white;
}

.footer-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: "Courier New", Courier, monospace;
}

.social-icons {
  margin-top: 10px;
  margin-bottom: 4.6vw;
  cursor: pointer;
}

.footer-logo {
  width: 10vw;
  height: auto;
}

.contact {
  display: flex;
  flex-direction: column;
  color: white;
}

/* CSS for side menu */

.sidenav {
  height: 80%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 5; /* Stay on top */
  /*top: 0;*/ /* Stay at the top */
  left: 0;
  background: linear-gradient(-90deg, #6200ee, #ab32e5); /* Black*/
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 5px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  display: flex;
  justify-content: flex-start;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  border-radius: 4px;
  margin: 1px 1px 0 1px;
  font-size: 23px;
  color: white;
  transition: 0.3s;
}

.menu-background-cover {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  background-color: black;
  opacity: 0.4;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: grey;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

.menu-icon {
  width: 12vw;
  height: auto;
  top: 0;
  margin-bottom: 10px;
}

.menu-header {
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* Search Modal */
.search-results-modal-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  margin-left: 2vw;
  height: auto;
  position: absolute;
  top: 0;
  left: -2vw;
}

.search-results-modal {
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: visible;
  width: 100vw;
  height: auto;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  opacity: 1;
  background: #03183f; /*#001133;*/
  z-index: -4;
  transition: 0.5s;
  transform: translateZ(-120vh);
}

.close_btn_ {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 14vh;
  right: 10px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-left: 95vw;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  font-size: 40px;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  border-radius: 100%;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      white 46%,
      white 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%, white
          56%, transparent 56%, transparent 100%);
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  z-index: 21;
  opacity: 0.5;
  cursor: pointer;
}

.note_btn_ {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50vh;
  left: 10px;
  box-sizing: border-box;
  width: 40px;
  height: 70px;
  margin-left: 0vw;
  min-width: 40px;
  max-width: 40px;
  max-height: 70px;
  min-height: 40px;
  font-size: 40px;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  border-radius: 10%;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      white 46%,
      white 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%, white
          56%, transparent 56%, transparent 100%);
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  z-index: 21;
  opacity: 0.5;
  cursor: pointer;
}

.close_btn_filter {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5vh;
  right: 10px;
  position: sticky;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-left: 88vw;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  font-size: 40px;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  border-radius: 100%;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      white 46%,
      white 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%, white
          56%, transparent 56%, transparent 100%);
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  z-index: 21;
  opacity: 0.5;
  cursor: pointer;
}

.view_post {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-left: 1vw;
  overflow-x: hidden;
  position: absolute;
  visibility: hidden;
  top: 0;
  z-index: 0;
}

.view_post_holder {
  overflow-y: scroll;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  opacity: 1;
  background: white;
  z-index: 0;
  transition: 0.5s;
  transform: translateZ(-100vh);
  overflow-x: hidden;
}

.share_post {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  /* position: relative; */
  visibility: visible;
  top: 0;
  z-index: 12;
}

.share_post_holder {
  overflow-y: scroll;
  visibility: visible;
  width: 100vw;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  opacity: 1;
  background: white;
  z-index: 10;
  transition: 0.5s;
  overflow-x: hidden;
}

.share_load_spiner {
  width: "100%";
  height: 55vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#section-f {
  margin-top: 0px;
  display: flex;
  flex-direction: column; /*new */
  align-items: center;
  justify-content: flex-start; /*center;*/
  overflow: hidden;
}

#drop-menu-section-search {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
  z-index: 3;
}
/* End of Search Modal */

/* Slider */

.range-slider {
  display: flex; /*none*/
  justify-content: center;
  margin-top: 30px;
  transition: 1s;
}

.range-slider-2 {
  display: flex; /* none*/
  justify-content: center;
  margin-top: 30px;
  transition: 1s;
}
.range-slider-3 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  transition: 1s;
}

.range-slider-4 {
  display: none;
  justify-content: center;
  margin-top: 0px;
  transition: 1s;
}

.range-slider > .middle,
.range-slider-2 > .middle,
.range-slider-3 > .middle,
.range-slider-4 > .middle {
  display: flex;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: relative;
  max-width: 80vw;
  min-width: 80vw;
  /* max-width: 1000px; */
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  z-index: 3;
  color: #ab32e5;
}

.slider-labels-search-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  z-index: 3;
  color: #3f51b5;
}

.slider,
.slider2,
.slider3,
.slider4,
.slider5 {
  position: relative;
  z-index: 1;
  height: 6px;
  margin: 0 15px;
}

.slider > .track,
.slider2 > .track,
.slider3 > .track,
.slider4 > .track,
.slider5 > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #c6aee7;
}

.slider > .range,
.slider2 > .range,
.slider3 > .range,
.slider4 > .range,
.slider5 > .range {
  position: absolute;
  z-index: 2;
  /* left: 25%; */
  /* right: 25%; */
  left: 0%;
  right: 0%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #6200ee;
}

.slider > .thumb,
.slider2 > .thumb,
.slider3 > .thumb,
.slider4 > .thumb,
.slider5 > .thumb {
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  top: 4.2px;
  background-color: #ab32e5;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(98, 0, 238, 0.1);
}

.slider > .thumb.left,
.slider2 > .thumb.left,
.slider3 > .thumb.left,
.slider4 > .thumb.left,
.slider5 > .thumb.left {
  /* left: 25%; */
  left: 0%;
  transform: translate(-15px, -10px);
}

.slider > .thumb.right,
.slider2 > .thumb.right,
.slider3 > .thumb.right,
.slider4 > .thumb.right,
.slider5 > .thumb.right {
  /* right: 25%; */
  right: 0%;
  transform: translate(15px, -10px);
}

.slider > .thumb.hover,
.slider2 > .thumb.hover,
.slider3 > .thumb.hover,
.slider4 > .thumb.hover,
.slider5 > .thumb.hover {
  box-shadow: 0 0 0 20px rgba(98, 0, 238, 0.1);
}

.slider > .thumb.active,
.slider2 > .thumb.active,
.slider3 > .thumb.active,
.slider4 > .thumb.active,
.slider5 > .thumb.active {
  box-shadow: 0 0 0 40px rgba(98, 0, 238, 0.2);
}

input[type="range"] {
  position: absolute;
  pointer-events: auto;
  -webkit-appearance: none;
  z-index: 2;
  height: 8px;
  width: 100%;
  opacity: 0;
  left: -2px;
}

input[type="range"]::-ms-track {
  position: absolute;
  pointer-events: auto;
  -webkit-appearance: none;
  z-index: 2;
  height: 8px;
  width: 100%;
  opacity: 0;
  left: -2px;
}

input[type="range"]::-ms-fill-upper {
  border-radius: 6px;
}
input[type="range"]::-ms-fill-lower {
  border-radius: 6px;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  pointer-events: auto;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}

input[type="range"]::-ms-thumb {
  pointer-events: auto;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}

/*end of slider*/

/*drop down menus */

.drop-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #ffdf00; /*#6200ee;*/
  width: 80vw;
  color: white; /*#ab32e5;*/
  padding: 16px;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
}

.drop-button-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #ffdf00; /*#6200ee;*/
  width: 80vw;
  color: white; /*#ab32e5;*/
  padding: 16px;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
}

.drop-button2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #ffdf00; /*#6200ee;*/
  width: 80vw;
  color: white; /*#ab32e5;*/
  padding: 16px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
}

.drop-button2-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #ffdf00; /*#6200ee;*/
  width: 80vw;
  color: white; /*#ab32e5;*/
  padding: 16px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
}

.drop-button2-sort {
  display: none;
}

@media screen and (max-width: 845) {
  .drop-menu-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80vw;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 3;
  }
}

#drop-menu-section {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100vw;
  z-index: 3;
}

#topper {
  position: absolute; /*new*/
  top: -100px; /*new*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  z-index: 3;
  color: white;
}

#topper p:hover {
  color: #6200ee;
  text-decoration: underline;
  cursor: pointer;
}

#topper .phrase1 {
  font-family: 'TiemposFineWeb-Light,Georgia,Times,"Times New Roman",serif';
  font-size: 25px;
  font-weight: bolder;
}

#topper .phrase2 {
  font-family: 'TiemposFineWeb-Light,Georgia,Times,"Times New Roman",serif';
  color: yellow;
  font-size: 25px;
  font-weight: bolder;
}

.categories-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow-x: hidden;
}

#property-type-web {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  font-weight: bolder;
  cursor: pointer;
  color: #c6aee7;
  margin-bottom: 2em;
  z-index: 3;
  user-select: none;
}

#property-type-web p {
  display: flex;
  justify-content: center;
  max-width: 20vw;
  margin-top: 3em;
  font-size: 1.4vw;
  color: white;
  text-align: center;
}

#property-type-web p:hover {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  color: yellow !important;
  cursor: pointer !important;
  /* text-decoration: underline; */
}

#property {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin: 0 1vw 0 1vw;
  flex-wrap: wrap;
  transition: 0.8;
}

#property_mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 0;
  flex-wrap: wrap;
  transition: 0.8;
}

@media screen and (min-width: 845px) {
  .web-view-search-more {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
  }

  .mobile-view-search-more {
    display: none;
  }
}

@media screen and (max-width: 845px) {
  .web-view-search-more {
    display: none;
  }

  .mobile-view-search-more {
    display: flex;
  }
}

.type_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  height: 35px;
}

.type_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  /* background-color: #ffdf00; */
  border: 0.5px solid grey;
  margin: 0 7vw 0 1vw;
}

.check_mark {
  font-size: 12px;
  color: #ffdf00;
}

.type_name {
  display: flex;
  color: white;
  font-size: 15px;
}

.property {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ab32e5;
  border-radius: 9px 9px 9px 9px;
  background-color: transparent;
  border: solid 1px #ab32e5;
  min-width: 30vw;
  min-width: 12vw;
  font-size: 11px;
  height: 40vw;
  margin-bottom: 30px;
  cursor: pointer;
}

.property:hover {
  color: white;
  font-size: 12px;
}

.icon {
  font-size: 40px;
  margin-bottom: 20px;
}

.filter-icon {
  font-size: 15px;
  color: gold;
  font-weight: bolder;
}

#bedroom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
}

#bedroom span {
  display: none;
  font-size: 13px;
  width: 80%;
  text-align: center;
}

.bedroom-amount {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bedroom-amount .icon {
  font-size: 15px;
  top: 50%;
  left: 50%;
  margin: 50% 50%;
}

.bedroom-amount .display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px 9px 9px 9px;
  width: 20vw;
  height: 40px;
  background-color: #c6aee7;
}

/* Plus Minus Circle */

.cssCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px; */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* padding: 0px; */

  background: yellow; /*#779BCA;*/
  border: 1px solid #003580;
  color: #003580;
  /* text-align: center; */
  /* -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
    transition: color 0.2s linear; */

  font: 30px Arial, sans-serif;
  font-weight: 900;
}

.cssCircleClose {
  top: "10px";
  right: "5px";
  position: "fixed";

  justify-content: center;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;

  width: 25px;
  height: 25px;
  padding: 0px;

  background: yellow; /*#779BCA;*/
  border: 1px solid #003580;
  color: #003580;
  /* text-align: center; */
  /* -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
    transition: color 0.2s linear; */

  font: 30px Arial, sans-serif;
  font-weight: 900;
}

.cssCircle:hover {
  background: #3f69a0;
  cursor: pointer;
}

.minusSign {
  /* line-height: .7em; */
  user-select: none;
}

.plusSign {
  /* line-height: 0.85em; */
  user-select: none;
}

.minusSign:hover,
.plusSign:hover {
  color: white;
}

.image_1_info {
  visibility: visible;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.8;
  background: hsl(240, 100%, 5%, 0.6);
  width: 100vw;
  height: 50vh;
  transition: 0.2s;
  transform: translateZ(-100vh);
  z-index: 1;
}

.text-info {
  visibility: visible;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50vh;
  font-family: DomineWebRegular, Georgia, Palatino Linotype, serif;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 0 5% 0 5%;
  transform: translateZ(-100vh);
  opacity: 1;
  z-index: 5;
  transition: 0.6s;
}

/* transform: translateZ(-100vh) */

.container_landing_page {
  background: white;
  max-height: 420px;
  height: 50vh;
  width: 100vw;
  margin: 0;
}

.container_landing_page:hover .image_1_info {
  visibility: visible;
  opacity: 0.8;
  width: 100vw;
  transform: translateZ(100vh);
}

.container_landing_page:hover .text-info {
  visibility: visible;
  /* opacity: 1; */
  width: 100vw;
  transform: translateZ(100vh);
  opacity: 1;
}

.nav-bar {
  visibility: hidden;
}

.navigational_bar {
  display: hidden;
  visibility: hidden;
}

.navigational_bar_mobile {
  visibility: visible;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 60px;
  min-height: 60px;
  height: auto;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vw 0 1vw;
  z-index: 11;
  color: white;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f7f7f7),
    to(#e7e7e7)
  );
  background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  box-shadow: 0px 2px 6px #aaa, inset 0px 2px 3px #fff;
  transition: 0.5s;
  /*overflow-y: hidden;  new */
  margin-bottom: 1vh;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited,
a:hover,
a:active {
  color: inherit;
}

.search-field-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 845px) {
  /*used to be 810px*/

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: relative;
    max-width: 54vw;
    min-width: 54vw;
  }

  .drop-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 20vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .drop-button-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 20vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .drop-button2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 17vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .drop-button2-type {
    display: none;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 17vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .drop-button2-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: solid 2px #6200ee; */
    width: 17vw;
    height: 50px;
    color: black;
    padding: 16px;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
  }

  .full-img {
    display: block;
    width: 100vw;
    height: 420px;
    object-fit: cover;
    border: solid 1px white;
  }

  .image_1_info {
    visibility: visible;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    opacity: 0.8;
    background: hsl(240, 100%, 5%, 0.6);
    width: 100vw;
    height: 420px;
    transition: 0.2s;
    transform: translateZ(-100vh);
    z-index: 1;
  }

  .text-info {
    visibility: visible;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 420px;
    font-family: DomineWebRegular, Georgia, Palatino Linotype, serif;
    color: white;
    font-size: 16px;
    transform: translateZ(-100vh);
    opacity: 1;
    z-index: 5;
    transition: 0.6s;
  }

  /* transform: translateZ(-100vh) */

  .container_landing_page {
    background: white;
    max-height: 420px;
    height: 420px;
    width: 100vw;
    margin: 0;
  }

  .container_landing_page:hover .image_1_info {
    visibility: visible;
    opacity: 0.8;
    width: 100vw;
    transform: translateZ(100vh);
  }

  .container_landing_page:hover .text-info {
    visibility: visible;
    /* opacity: 1; */
    width: 100vw;
    transform: translateZ(100vh);
    opacity: 1;
  }

  .share_post {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
    /* position: relative; */
    visibility: visible;
    top: 0;
    z-index: 12;
  }

  .share_post_holder {
    overflow-y: scroll;
    visibility: visible;
    width: 100vw;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    opacity: 1;
    background: white;
    z-index: 10;
    transition: 0.5s;
    overflow-x: hidden;
  }

  #topper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 120px; /*new*/
    margin: 10px;
    z-index: 3;
    color: white;
  }

  #topper .phrase1,
  #topper .phrase2 {
    font-family: 'TiemposFineWeb-Light,Georgia,Times,"Times New Roman",serif';
    font-size: 38px;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
    z-index: 2;
  }

  #section-a {
    position: absolute;
    top: 320px; /*330px;*/
    display: flex;
    flex-direction: column; /*new */
    align-items: center;
    justify-content: flex-end /*center; */;
  }

  .search-field-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #template {
    width: 80vw;
    margin-top: 20px;
    height: 40px;
    background: white;
    padding: 10px 15px;
    margin-bottom: -30px;
  }

  /*hidden intentionally */
  .filter-button {
    display: none;
  }

  #property-type-web {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    cursor: pointer;
    color: #ab32e5;
    user-select: none;
  }

  #search-toggle-property_type {
    display: none;
  }

  .filter-icon {
    font-size: 22px;
    color: gold;
    font-weight: bolder;
  }

  /*  .search-button input{
        display: inline-block; 
        padding: 21px 65px;
        font-size: 20px;
        width: 10vw;
        height: 40px;
        border-radius: 0;
      /* -webkit-appearance: none; */

  .search-button-mobile {
    display: none;
  }

  .submit-button-2 {
    cursor: pointer;
    padding: 1.7px 3px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    width: 10vw;
    height: 40px;
    background: transparent;
    transition: 0.5s;
    z-index: 2;
    font-weight: bolder;
    color: #001133;
    font-size: 16px;
  }

  .search-button-span {
    cursor: pointer;
    padding: 1.7px 3px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    width: 10vw;
    height: 40px;
    background: yellow;
    transition: 0.5s;
    z-index: 1;
    /*border-radius: 0 2px 2px 0;*/
    border-radius: 0;
  }

  .span-container {
    position: absolute;
    /* padding: 1.7px 3px; */
    width: 10vw;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .drop-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 23vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .drop-button-type {
    display: none;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px #6200ee;
    width: 23vw;
    color: #c6aee7;
    padding: 16px;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  #drop-menu-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /*100vw*/
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 3;
    transition: 1s;
  }

  .drop-menu-section {
    display: flex;
    justify-content: center;
    width: 80vw;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
  }

  .toggle-buy {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    border-radius: 8px 0 0 8px;
    width: 12vw;
    padding: 0;
    border-left: 1px black;
    border-bottom: 0px black;
    border-top: 0px black;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    text-transform: capitalize;
  }

  .toggle-buy span {
    font-size: 11px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
  }

  .toggle-buy-span {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 3px;
    animation-name: rotate180;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    /* transition-property: transform, color, font-size;
        transition-duration: 0.4s; */
  }

  .toggle-buy-span-not-clicked {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 3px;
    animation-name: rotate180;
    animation-duration: 0.5s;
    animation-iteration-count: inherit;
  }

  .toggle-buys span {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transition: 2s;
  }

  @keyframes rotate180 {
    /*    0% {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);         
        }
        25% {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            -ms-transform: rotate(135deg);         
        }
        50% {transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);         
        }
        75%{
            transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
            -moz-transform: rotate(225deg);
            -o-transform: rotate(225deg);
            -ms-transform: rotate(225deg);         
        } */
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
    }
  }

  /* .search-input input{
        display: inline-block; 
        padding: 1.7px 3px;
        font-size: 20px;
        width: 41vw;
        height: 40px;
        border-radius: 0;
        padding-left: 20vw;
    /*  -webkit-appearance: none; */

  .location-input {
    font-size: 12px !important;
    padding-left: 0vw;
    padding-top: 0;
    padding-bottom: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 47vw !important;
    border-radius: 0;
    color: black !important;
    border: 0px solid grey;
    /*  -webkit-appearance: none; */
  }

  .type-field {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
    font-size: 11px !important;
    font-weight: bolder;
    width: 12vw;
    padding: 0;
    border-left: 1px black;
    border-bottom: 0px black;
    border-top: 0px black;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .type-field span {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bedroom-amount .display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px 9px 9px 9px;
    width: 10vw;
    height: 40px;
    background-color: #c6aee7;
  }

  #property {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 10vw 0 10vw;
    flex-wrap: wrap;
    transition: 0.8;
  }

  #property_mobile {
    display: none;
  }

  .property {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ab32e5;
    background-color: #03183f; /*#1b294a;/*transparent;*/
    border: solid 1px #ab32e5;
    border-radius: 9px 9px 9px 9px;
    min-width: 12vw;
    font-size: 11px;
    height: 15vw;
    margin: 0 10px 30px 10px;
    cursor: pointer;
  }

  /*Slider */

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: relative;
    width: 80vw;
  }
}

.ant-slider-handle,
.ant-slider-handle-1,
.ant-slider-handle.ant-slider-handle-1,
.ant-slider-handle.ant-slider-handle-2,
.ant-slider-handle:hover,
.ant-slider-handle-1:hover,
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging,
.ant-slider-handle.ant-tooltip-open,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #6200ee !important;
}
.ant-slider-track,
.ant-slider-track-1 {
  border-color: #6200ee !important;
  background-color: #6200ee !important;
}
.ant-slider:hover .ant-slider-track {
  background-color: #6200ee !important;
}

/* .slick-slide.slick-active.slick-current {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: 400px !important;
} */

/*Image CArousel */
/* Hide the images by default */

@media screen and (max-width: 845px) {
  .mySlides {
    display: none;
    width: 100%;
    height: 400px;
  }

  .ant-carousel {
    display: block;
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 845px) {
  /* .ant-carousel {
    display: none;
  } */

  .mySlides {
    display: flex;
    width: 100%;
    height: 400px;
  }

  .mySlidesExpandedView {
    display: flex;
    width: 100%;
    height: 100%;
  }

  /* Next & previous buttons */
  .prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    margin: 0 2px;
    border-radius: 50%;
    display: none;
    transition: background-color 0.6s ease;
    font-weight: bolder;
  }

  .active,
  .dot:hover {
    display: inline-block;
    color: white;
    font-size: 12px;
    margin: 0 2px 0 0;
  }

  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 845px) {
  .dot {
    cursor: pointer;
    margin: 0 2px;
    border-radius: 50%;
    display: none;
    transition: background-color 0.6s ease;
    font-weight: bolder;
  }

  .active,
  .dot:hover {
    display: flex !important;
    color: white;
    font-size: 12px;
    margin: 0 2px 0 0;
  }
}

.tags {
  cursor: pointer;
}

.ant-select:not(.abt-select-disabled):hover .ant-select-selector {
  border-color: grey !important;
  border-right-width: 0px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple
  .ant-select-selector {
  border-color: grey !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  /* border-color: #40a9ff; */
  border-color: grey !important;
}

.ant-image-img {
  height: "400px !important";
  width: "100%";
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: transparent;
}

.ant-modal-content {
  background-color: "white"; /*#001133;*/
}

.bath_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 50px;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #ecf0f3;
  cursor: pointer;
  transition: 2s;
}

.bath_buttons:active {
  /* border: 1px solid grey; */
  font-size: 40px;
}

.bath-input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 30px;
  width: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #ecf0f3;
}

.MuiPaper-elevation4 {
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
  box-shadow: none !important;
}
