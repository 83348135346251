.inbox-banner {
    max-height: 223px;
}
.inbox {
    background: rgba(255, 255, 255, 0.7);
    display: table;
    border-radius: 5px;
    position: relative;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15) !important;
}

.inbox .messenger-list {
    display: table-cell;
    width: 30%;
    border-right: 1px solid #f4f6fa;
    vertical-align: top;
}
.inbox .messenger-list .search-bar {
    padding: 20px 30px 20px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox .messenger-list .search-bar input {
    border: none;
    width: 100%;
    background: url("../../assets/svg/search-gray.svg") no-repeat left center;
    background-position-x: 15px;
    background-size: 15px 15px;
    padding-left: 38px;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
    border: 1px solid transparent;
}
.inbox .messenger-list .search-bar input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #f1f1f1;
    border-radius: 30px;
}
.inbox .messenger-list button {
    border-radius: 20px;
    margin: 20px 25px;
    padding: 7px 20px;
    font-size: 13px;
    font-weight: normal;
}
.inbox .messenger-list ul.message-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    float: left;
    height: 405px;
}
.inbox .messenger-list ul.message-list li {
    position: relative;
    width: 100%;
    background: transparent;
    padding: 20px 25px;
    transition: all 0.5s ease;
    border-bottom: 1px solid #f4f6fa;
}
.inbox .messenger-list ul.message-list li.active {
    background: rgba(28, 133, 255, 0.05);
}
.inbox .messenger-list ul.message-list li.active:before {
    content: "";
    border-left: 3px solid #1c85ff;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.inbox .messenger-list ul.message-list li.read a div.messenger-message div.message-summery {
    font-weight: normal;
}
.inbox .messenger-list ul.message-list li.unread {
    background: #fafafa;
}
.inbox .messenger-list ul.message-list li.unread a div.messenger-message div.message-summery {
    font-weight: 500;
}
.inbox .messenger-list ul.message-list li:last-child {
    border-bottom: none;
}
.inbox .messenger-list ul.message-list li:first-child {
    border-top: 1px solid #f4f6fa;
}
.inbox .messenger-list ul.message-list li:hover {
    background: #f5f6fa;
}
.inbox .messenger-list ul.message-list li a {
    display: block;
    color: #424457;
    text-decoration: none;
}
.inbox .messenger-list ul.message-list li a div.messenger-icon {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: top;
}
.inbox .messenger-list ul.message-list li a div.messenger-icon img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
.inbox .messenger-list ul.message-list li a div.messenger-icon i {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
}
.inbox .messenger-list ul.message-list li a div.messenger-message {
    display: inline-block;
    width: calc(100% - 55px);
    vertical-align: top;
}
.inbox .messenger-list ul.message-list li a div.messenger-message h5.message-title {
    font-size: 12px;
    font-weight: bold;
    margin: 4px 0px 0px;
}
.inbox .messenger-list ul.message-list li a div.messenger-message div.message-summery {
    font-size: 11px;
    width: 75%;
    line-height: 2em;
}
.inbox .messenger-list ul.message-list li a span.date-time {
    position: absolute;
    top: 25px;
    right: 15px;
    font-size: 11px;
}
.inbox .chat-box {
    display: table-cell;
    width: 70%;
    position: relative;
}
.inbox .chat-box .chat-header {
    padding: 10px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox .chat-box .chat-header div.header-icon {
    height: 60px;
    width: 60px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.inbox .chat-box .chat-header div.header-icon i {
    position: absolute;
    bottom: 0;
    right: 3px;
}
.inbox .chat-box .chat-header div.header-icon img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.inbox .chat-box .chat-header div.header-details {
    width: calc(100% - 85px);
    display: inline-block;
    vertical-align: top;
}
.inbox .chat-box .chat-header div.header-details h5.user-icon {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 700;
}
.inbox .chat-box .chatter {
    width: 100%;
    float: left;
    padding: 20px 0px;
    height: 337px;
}
.inbox .chat-box .chatter .chat-wrapper {
    max-width: 75%;
    clear: both;
    padding-bottom: 0px;
}
.inbox .chat-box .chatter .chat-wrapper:last-child {
    padding-bottom: 0;
}
.inbox .chat-box .chatter .chat-wrapper.left {
    float: left;
    padding-left: 40px;
}
.inbox .chat-box .chatter .chat-wrapper.left div.chatter-title,
.inbox .chat-box .chatter .chat-wrapper.left div.time-plate {
    text-align: left;
}
.inbox .chat-box .chatter .chat-wrapper.left div.chatter-message {
    border-radius: 20px 20px 20px 0;
    background: #edf0f5;
}
.inbox .chat-box .chatter .chat-wrapper.right {
    float: right;
    padding-right: 40px;
}
.inbox .chat-box .chatter .chat-wrapper.right div.chatter-title,
.inbox .chat-box .chatter .chat-wrapper.right div.time-plate {
    text-align: right;
}
.inbox .chat-box .chatter .chat-wrapper.right div.chatter-message {
    border-radius: 20px 20px 0px 20px;
    background: #1c85ff;
    color: #fff;
}
.inbox .chat-box .chatter .chat-wrapper div.chatter-title {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
}
.inbox .chat-box .chatter .chat-wrapper div.chatter-message {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
}
.inbox .chat-box .chatter .chat-wrapper div.chatter-message p {
    margin-bottom: 0;
}
.inbox .chat-box .chatter .chat-wrapper div.time-plate {
    font-size: 11px;
    color: #afaeba;
    width: 100%;
}

.inbox .chat-box .chatter .chat-wrapper div.error-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    color: red;
}

.inbox .chat-box .chat-footer {
    padding: 25px;
    border-top: 1px solid #f4f6fa;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 0 0 5px 0;
    width: 100%;
    background: #fff;
}
.inbox .chat-box .chat-footer .chat-input {
    width: 100%;
    float: left;
    background: #edf2f6;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.inbox .chat-box .chat-footer .chat-input textarea {
    border: none;
    font-size: 12px;
    width: calc(100% - 100px);
    float: left;
    background: transparent;
    margin-bottom: 10px;
    resize: none;
}
.inbox .chat-box .chat-footer .chat-input textarea:focus {
    outline: none;
}
.inbox .chat-box .chat-footer .chat-input textarea:focus ~ button.chat-btn {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.inbox .chat-box .chat-footer .chat-input ul.chat-action {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(100% - 100px);
    float: left;
}
.inbox .chat-box .chat-footer .chat-input ul.chat-action li {
    display: inline-block;
    margin-right: 10px;
}
.inbox .chat-box .chat-footer .chat-input ul.chat-action li a {
    display: block;
}
.inbox .chat-box .chat-footer .chat-input ul.chat-action li a img {
    width: 15px;
    height: 15px;
}
.inbox .chat-box .chat-footer .chat-input button.chat-btn {
    width: 80px;
    position: absolute;
    top: 26px;
    right: 25px;
    border-radius: 25px;
    padding: 6px 15px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
}
.inbox .chat-box .chat-footer .chat-input button.chat-btn img {
    width: 25px;
    height: 25px;
}

.back-icon {
    position: relative;
    display: flex;
    display: flex;
    align-items: center;
}

.user-name {
    font-size: 18px;
    font-weight: 700;
}
/*
 * Support for various screen sizes
*/
@media screen and (max-width: 1440px) {
}

/* Small laptops*/
@media screen and (max-width: 1024px) {
}

/* Tablet*/
@media screen and (max-width: 768px) {
    .inbox .chat-box {
        display: none;
    }
    .inbox .messenger-list {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }

    .chat-header {
        display: flex;
        justify-content: space-between;
    }

    .user-name {
        font-size: 13px;
        font-weight: 700;
    }
}

/* Mobile phones*/
@media screen and (max-width: 425px) {
    .inbox .chat-box {
        display: none;
    }
    .inbox .messenger-list {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Mobile phones*/
@media screen and (max-width: 375px) {
    .inbox .chat-box {
        display: none;
    }
    .inbox .messenger-list {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Mobile phones*/
@media screen and (max-width: 320px) {
    .inbox .chat-box {
        display: none;
    }
    .inbox .messenger-list {
        display: table-cell;
        width: 100%;
        border-right: 1px solid #f4f6fa;
        vertical-align: top;
    }
}

/* Extra large screens*/
@media screen and (min-width: 1450px) {
}

.inbox .chat-box1 {
    display: table-cell;
    width: 70%;
    position: relative;
}
.inbox .chat-box1 .chat-header {
    padding: 10px 30px;
    float: left;
    border-bottom: 1px solid #f4f6fa;
    width: 100%;
}
.inbox .chat-box1 .chat-header div.header-icon {
    height: 60px;
    width: 60px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.inbox .chat-box1 .chat-header div.header-icon i {
    position: absolute;
    bottom: 0;
    right: 3px;
}
.inbox .chat-box1 .chat-header div.header-icon img {
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
.inbox .chat-box1 .chat-header div.header-details {
    width: calc(100% - 85px);
    display: inline-block;
    vertical-align: top;
}
.inbox .chat-box1 .chat-header div.header-details h5.user-icon {
    margin-bottom: 5px;
}
.inbox .chat-box1 .chatter {
    width: 100%;
    float: left;
    padding: 20px 0px;
    height: 337px;
}
.inbox .chat-box1 .chatter .chat-wrapper {
    max-width: 75%;
    clear: both;
    padding-bottom: 0px;
}
.inbox .chat-box1 .chatter .chat-wrapper:last-child {
    padding-bottom: 0;
}
.inbox .chat-box1 .chatter .chat-wrapper.left {
    float: left;
    padding-left: 40px;
}
.inbox .chat-box1 .chatter .chat-wrapper.left div.chatter-title,
.inbox .chat-box1 .chatter .chat-wrapper.left div.time-plate {
    text-align: left;
}
.inbox .chat-box1 .chatter .chat-wrapper.left div.chatter-message {
    border-radius: 20px 20px 20px 0;
    background: #edf0f5;
}
.inbox .chat-box1 .chatter .chat-wrapper.right {
    float: right;
    padding-right: 40px;
}
.inbox .chat-box1 .chatter .chat-wrapper.right div.chatter-title,
.inbox .chat-box1 .chatter .chat-wrapper.right div.time-plate {
    text-align: right;
}
.inbox .chat-box1 .chatter .chat-wrapper.right div.chatter-message {
    border-radius: 20px 20px 0px 20px;
    background: #1c85ff;
    color: #fff;
}
.inbox .chat-box1 .chatter .chat-wrapper div.chatter-title {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
}
.inbox .chat-box1 .chatter .chat-wrapper div.chatter-message {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
}
.inbox .chat-box1 .chatter .chat-wrapper div.chatter-message p {
    margin-bottom: 0;
}
.inbox .chat-box1 .chatter .chat-wrapper div.time-plate {
    font-size: 11px;
    color: #afaeba;
    width: 100%;
}
.inbox .chat-box1 .chat-footer {
    padding: 25px;
    border-top: 1px solid #f4f6fa;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 0 0 5px 0;
    width: 100%;
    background: #fff;
}
.inbox .chat-box1 .chat-footer .chat-input {
    width: 100%;
    float: left;
    background: #edf2f6;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.inbox .chat-box1 .chat-footer .chat-input textarea {
    border: none;
    font-size: 12px;
    width: calc(100% - 100px);
    float: left;
    background: transparent;
    margin-bottom: 10px;
    resize: none;
}
.inbox .chat-box1 .chat-footer .chat-input textarea:focus {
    outline: none;
}
.inbox .chat-box1 .chat-footer .chat-input textarea:focus ~ button.chat-btn {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.inbox .chat-box1 .chat-footer .chat-input ul.chat-action {
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(100% - 100px);
    float: left;
}
.inbox .chat-box1 .chat-footer .chat-input ul.chat-action li {
    display: inline-block;
    margin-right: 10px;
}
.inbox .chat-box1 .chat-footer .chat-input ul.chat-action li a {
    display: block;
}
.inbox .chat-box1 .chat-footer .chat-input ul.chat-action li a img {
    width: 15px;
    height: 15px;
}
.inbox .chat-box1 .chat-footer .chat-input button.chat-btn {
    width: 80px;
    position: absolute;
    top: 26px;
    right: 25px;
    border-radius: 25px;
    padding: 6px 15px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
}
.inbox .chat-box1 .chat-footer .chat-input button.chat-btn img {
    width: 25px;
    height: 25px;
}
