#search-bar {
    @media screen and (max-width: 555px) {
        .view-mobile {
            display: none;
        }
    }
    @media screen and (min-width: 556px) {
        .view-mobile {
            display: block;
        }
    }
}
