#org-dash-users {
    min-height: 100vh;

    .branch-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        box-shadow: 2px 2px 20px #d3d3d3;
        padding: 15px 15px;
        border-radius: 9px;
        background-color: #fff;
        width: 100%;
    }
}
