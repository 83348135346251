#preference-listing {
    .form-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    form {
        background: #fff; //#ececec;
        box-shadow: 2px 2px 20px #cecece;
        border-radius: 10px;
        margin: 0 auto;
        padding: 40px 40px;

        @media screen and (min-width: 300px) {
            max-width: 100% !important;
        }

        @media screen and (min-width: 576px) {
            max-width: 95% !important;
        }

        @media screen and (min-width: 768px) {
            max-width: 80% !important;
        }

        @media screen and (min-width: 1100px) {
            max-width: 60% !important;
        }
    }
}
