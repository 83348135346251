#footer {
    width: 100%;
    background-color: #4d4d4d;
    color: white;
    padding: 20px 5px;

    .main-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .footer-header {
        color: white;
        font-size: 16px;
        font-weight: 600;
    }

    .footer-links {
        font-size: 13px;
        color: white;
    }

    .footer-links:hover {
        color: #805500;
        text-decoration: underline;
    }

    .footer-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .final-line {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-icon {
        margin: 0 8px;
        color: white;
        cursor: pointer;
    }

    .footer-icon:hover {
        background-color: #805500;
        color: white;
    }

    .grid-container {
        margin-bottom: 25px;
    }

    .info-page-sublinks {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 25px;
    }
}
