.policy-body{
    padding: 8em 10em 2em 10em;
    font-family: 'Poppins', sans-serif;
    background-color: #f4f6f7;
}

.policy-section{
    margin-bottom: 5em;
    padding-top: 80px;
}

@media screen and (max-width: 1245px) {
    .policy-body{
        padding: 8em 6em 2em 6em;
        font-family: 'Poppins', sans-serif;
        background-color: #f4f6f7;
    }
    
    .policy-section{
        margin-bottom: 5em;
        padding-top: 80px;
    }    
}

@media screen and (max-width: 845px) {
    .policy-body{
        padding: 8em 4em 2em 4em;
        font-family: 'Poppins', sans-serif;
        background-color: #f4f6f7;
    }
    
    .policy-section{
        margin-bottom: 5em;
        padding-top: 80px;
    }    
}

@media screen and (max-width: 445px) {
    .policy-body{
        padding: 8em 1em 2em 1em;
        font-family: 'Poppins', sans-serif;
        background-color: #f4f6f7;
    }
    
    .policy-section{
        margin-bottom: 5em;
        padding-top: 80px;
    }    
}